$navbarLinkColor: #fff;
$navbarIconColor: #fff;
$navbarActiveLinkColor: #0a0d47;
$navbarActiveIconColor: #0a0d47;
$navbarActiveItemBgColor: #fff;
$navbarHeaderBgColor: #218cda;
$navbarBgColor: #218cda;
$navbarLenguageColor: #fff;
$navbarLenguageActiveColor: #0a0d47;
$navbarLenguageActiveBgColor: #fff;
$navbarToggleIconColor: #fff;
$navbarActiveSubItemBgColor:#9BCFF3;
$footerBg: #f6f8f9;
$headerBorder: #ecedee;
$inputBorder: #dfdfdf;
$placeholderColor: #a1a1a1;
$errorAlertBg: rgb(253, 237, 237);
$errorAlertText: rgb(95, 33, 32);
$errorAlertIcon: #d32f2f;
$successAlertBg: rgb(237, 247, 237);
$successAlertText: rgb(30, 70, 32);
$successAlertIcon: #2e7d32;
$mainTextColor: #0a0d47;

$white: #fff;
$whiteE9: #e9e9e9;
$whiteD9: #d9d9d9;
$whiteF9: #f9f9f9;
$whiteCC: #ccc;
$whiteEE: #eee;
$transparentWhite06: rgba(255, 255, 255, 0.6);
$blue000: #075185;
$blue300: #3278AA;
$blue200: #218CDA;
$blue777: #70B6E7;
$gray200: #212529;
$gray400: #415055;
$gray900: #999;
$grayDe: #dedede;
$green26F: #26f8ab;
$green50B: #50b996;
$yellowFAB: #fab900;
$yellowFFF4E5: #FFF4E5;
$orangeF6A25D: #F6A25D;
$brown663C00: #663C00;
$errorText: #DE3C39;
$red: red;
$redA70: #a70000;

