@import '../../../colors.scss';

.custom-input {
  display: flex;
  flex-direction: column;
  gap: 9px;
  width: 100%;
  input {
    width: 100%;
    border: 1px solid $inputBorder;
    padding: 16px 20px;
    color: $mainTextColor;
    &::placeholder {
      color: $placeholderColor;
    }
    &:focus-visible {
      outline: none;
    }
  }
  textarea {
    width: 100%;
    border: 1px solid $inputBorder;
    padding: 16px 20px;
    color: $mainTextColor;
    min-height: 130px;
    &::placeholder {
      color: $placeholderColor;
    }
    &:focus-visible {
      outline: none;
    }
  }
  .error {
    color: $errorText;
    font-size: 14px;
  }
  .instruction {
    color: $gray900;
    font-size: 14px;
    line-height: 21px
  }
  .input-holder {
    position: relative;
    .eye-icon-btn {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateY(-50%);
      svg {
        width: 20px;
        color: $mainTextColor;
      }
    }
  }
}