@import '../../../colors.scss';

.custom-auto-select {
  position: relative;
  .auto-select-dropdown {
    border: 1px solid $inputBorder;
    background-color: $white;
    margin-top: 12px;
    width: 200px;
    position: absolute;
    left: 0;
    z-index: 100;
    max-height: 200px;
    overflow-y: auto;
    ul {
      list-style-type: none;
      li {
        font-size: 14px;
        color: $gray200;
        padding: 10px 20px;
        &:hover {
          background-color: $whiteEE;
          cursor: pointer;
        }
      }
    }
  }
  .custom-select-loading-holder {
    .loader {
      width: 30px;
      height: 30px;
      border: 3px solid $blue200;
      border-bottom-color: transparent;
      margin: 3px 0px;
    }
  }
}