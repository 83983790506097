@import '../../../colors.scss';

.contract-list-holder {
  .add-contract-btn-holder {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 60px 0 50px 0;
  }
  .contract-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .contract-list-item {
      width: 100%;
      border: 1px solid $grayDe;
      padding: 20px;
      .contract-list-item-head {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;
        .contract-status {
          color: $white;
          font-size: 10px;
          background-color: $redA70;
          padding: 4px 7px;
          padding-top: 5px;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          &.pending {
            background-color: $yellowFAB;
          }
          &.active {
            background-color: $green50B;
          }
          &.inactive {
            background-color: $redA70;
          }
        }
        .contract-list-item-number {
          font-size: 14px;
          color: $gray900;
        }
      }
      .contract-list-item-address {
        font-size: 16px;
        color: $gray400;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contract-list-holder {
    .add-contract-btn-holder {
      justify-content: center;
    }
  }
}