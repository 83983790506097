.active-contract-layout-holder {
  position: relative;
  .unactive-contract-overlay {
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    z-index: 1;
    .unactive-contract-overlay-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .page-heading {
        max-width: 450px;
        &.pending {
          max-width: 700px;
        }
        h1 {
          font-size: 40px;
          line-height: 50px;
        }
      }
    }
    &.has-alerts {
      .unactive-contract-overlay-content {
        margin-top: 0px;
      }
    }
  }
}
