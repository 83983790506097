@import '../../../../../colors.scss';

.info-alert {
  background-color: $yellowFFF4E5;
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 8px 14px;
  padding-bottom: 14px;
  position: relative;
  margin-bottom: 30px;
  .info-alert-header {
    display: flex;
    .info-alert-head {
      display: flex;
      align-items: center;
      gap: 5px;
      svg {
        color: $orangeF6A25D;
        width: 21px;
      }

      h3 {
        font-size: 14px;
        line-height: 14px;
        font-weight: bold;
        margin-bottom: 0px;
        color: $orangeF6A25D;
        vertical-align: bottom;
      }
    }
    .close-btn {
      position: absolute;
      right: 0;
      top: 0;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 5px 8px;
      svg {
        width: 18px;
        color: $orangeF6A25D;
      }
    }
  }
  .info-alert-body {
    margin-top: 3px;
    padding: 0 2px;
    p {
      color: $brown663C00;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
