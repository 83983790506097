@import '../../colors.scss';

.layout-wrapper {
  display: flex;
  .main-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: $transparentWhite06;
    .main-content-wrapper {
      flex-grow: 1;
      width: 75%;
      padding-bottom: 180px;
      background-color: $white;
    }
    .background-holder {
      background-image: url('https://b2ctemplate-uat.aroundtown.de/templates/signin-azure-atcp/assets/images/b2cBg.jpeg');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center center;
      transform: scaleX(-1);
      position: fixed;
      top: 0;
      bottom: 90px;
      z-index: -1;
      right: 0;
      left: 220px;
      background-size: cover;
    }
    header {
      .header-content-wrapper {
        height: 65px;
        padding: 13px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $headerBorder;
        background-color: $white;
        width: 75%;
        .back {
          display: block;
          padding: 10px;
          svg {
            width: 18px;
          }
        }
        h1 {
          flex-grow: 1;
          text-align: center;
          color: $gray400;
          font-size: 18px;
          font-family: 'Red Hat Display', sans-serif;
          font-weight: 700;
        }
      }
    }
  }
}

.aroundtown_intro {
  position: relative;
  background-color: $whiteF9;
  padding: 13px 17px;
  padding-top: 20px;
  width: 100%;
  margin-top: 72px!important;
  font-size: 14px;
  line-height: 21px;
  max-width: 100%!important;
  display: block!important;
  &::before {
    content: '';
    height: 100%;
    width: 5px;
    background-color: $blue200;
    left: 0;
    top: 0;
    position: absolute;
  }
}

// MOBILE

.layout-wrapper.mobile {
  flex-direction: column;
  .main-wrapper {
    .main-content-wrapper {
      width: 90%;
      .page-heading {
        h1 {
          font-size: 35px;
          line-height: 44px;
        }
      }
    }
    .background-holder {
      left: 0;
      bottom: 0;
    }
    header {
      .header-content-wrapper {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .main-wrapper {
    min-height: unset!important;
    .main-content-wrapper {
      width: 90%;
      .page-heading {
        h1 {
          font-size: 35px;
          line-height: 44px;
        }
      }
    }
    .background-holder {
      left: 0;
      bottom: 0;
    }
    header {
      .header-content-wrapper {
        width: 90%;
      }
    }
  }
}