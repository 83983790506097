@import '../../colors.scss';

.privacy-policy-holder {
    h1 {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 30px;
        margin-top: 30px;
    }
    h2 {
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    p {
        font-size: 16px;
        margin-bottom: 5px;
        line-height: 20px;
    }
    span, a {
        font-size: 16px;
    }
}