@import '../../../colors.scss';

.add-contract-page-wrapper {
  .address-inputs-wrapper {
    display: flex;
    gap: 8px;
    width: 100%;
    .address-pair-inputs-holder {
      display: flex;
      gap: 8px;
    }
  }
}

@media screen and (max-width: '576px') {
  .add-contract-page-wrapper {
    .address-inputs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .address-pair-inputs-holder {
        display: flex;
        width: 100%;
        gap: 8px;
      }
    }
  }
}