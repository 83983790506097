@import '../../../../../colors.scss';

.navbar-wrapper {
  position: sticky;
  top: 0;
  height: 100vh;
  width: 220px;
  flex-shrink: 0;
  overflow: hidden;
  .menu-item-holder {
    padding: 2px 10px;
    .menu-item {
      padding: 10px;
      display: flex;
      align-items: center;
      text-decoration: none;
      border-radius: 4px;
      color: $navbarLinkColor;
      svg {
        height: 19px;
        margin-right: 10px;
        color: $navbarIconColor;
      }
      .toggle-submenu {
        margin-left: auto;
        position: relative;
        display: block;
        padding-top: 2px;
        svg {
          margin-right: 0;
          color: $navbarLinkColor;
        }
      }
      &.active {
        background-color: $navbarActiveItemBgColor;
        color: $navbarActiveLinkColor;
        svg {
          color: $navbarActiveIconColor;
        }
        .toggle-submenu {
          svg {
            color: $navbarActiveLinkColor;
          }
        }
      }
      &.open {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .submenu-wrapper {
      padding: 10px;
      .submenu-item-holder {
        list-style-type: none!important;
        .submenu-item {
          display: block;
          padding: 10px;
          color: $navbarLinkColor;
          text-decoration: none;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      &.active {
        border-top: 1px solid $white;
        background-color: $navbarActiveItemBgColor;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .submenu-item-holder {
          .submenu-item {
            color: $navbarActiveLinkColor;
          }
          &.active {
            border-left: 2px solid $blue000;
            .submenu-item {
              background-color: $navbarActiveSubItemBgColor;
            }
          }
        }
      }
    }
  }
}

.mobile {
  .navbar-wrapper {
    position: sticky;
    top: 0;
    height: auto;
    width: 100%;
    overflow: visible;
    z-index: 3;
    .menu-wrapper {
      padding-bottom: 30px;
    }
  }
}