@import '../../colors.scss';

.not-found-holder {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .not-found-number {
    font-size: 220px;
    color: $whiteE9;
  }
  .not-found-text {
    font-size: 36px;
    color: $whiteD9;
  }
}