@import '../../../../colors.scss';

.sidebar-wrapper {
  background-color: $navbarBgColor;
  height: 100%;
  .sidebar-header {
    padding: 9px 17px 9px 6px;
    background-color: $navbarHeaderBgColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: 65px;
    .toggler-icon {
      border: none;
      padding: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
    }
    .toggler-icon > div:not(:last-child) {
      margin-bottom: 3px;
    }
    .toggler-icon div {
      width: 20px;
      height: 3px;
      background-color: $navbarToggleIconColor;
    }
    .logo {
      img {
        width: 145px;
      }
    }
    .translate-wrapper {
      position: absolute;
      bottom: 20px;
      left: 20px;
      display: flex;
      gap: 10px;
      button {
        border: none;
        background-color: transparent;
        outline: none;
        color: $navbarLenguageColor;
        padding-left: 0;
        padding-right: 0;
        cursor: pointer;
        border-radius: 4px;
        padding: 10px;
        font-size: 12px;
        &.active {
          background-color: $navbarLenguageActiveBgColor;
          color: $navbarLenguageActiveColor;
        }
      }
    }
  }
}

.mobile {
  .sidebar-header {
    padding: 9px 17px 9px 17px;
    margin-bottom: 0;
    .translate-wrapper {
      position: static;
      bottom: unset;
      left: unset;
    }
  }
}