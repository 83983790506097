@import '../../../colors.scss';

.quick-links-holder {
  margin-top: 40px;
  .services-board-list-head {
    margin-bottom: 40px;

    h2 {
      font-size: 28px;
      color: $mainTextColor;
      font-family: 'Red Hat Display', sans-serif;
      font-weight: 700;
      text-align: center;
    }
  }

  .services-board-list-holder {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    max-width: 550px;
    margin: auto;

    .services-board-item-holder {
      cursor: pointer;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;

      .image-holder {
        width: 150px;
        height: 150px;
        padding: 15px;
        border: 1px solid $grayDe;

        svg {
          width: 118px;
          height: 118px;
        }
      }

      h3 {
        margin-top: 10px;
        text-align: center;
        font-size: 15px;
      }
    }
  }

  @media screen and (max-width: 650px) {
    .services-board-list-holder {
      grid-template-columns: 1fr 1fr;
      max-width: 350px;
      gap: 30px;
    }
  }
}