@import '../../colors.scss';

.uploaded-images-holder {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 460px;
  .uploaded-image-holder {
    width: 131px;
    height: 100px;
    border: 1px solid $inputBorder;
    display: flex;
    justify-content: center;
    position: relative;
    &.download {
      cursor: pointer;
    }
    .delete-attachment-button {
      position: absolute;
      right: -10px;
      top: -10px;
      background-color: $white;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        color: $gray400;
        width: 25px;
      }
    }
    img {
      width: 100px;
      height: 98px;
    }
  }
}