@import '../../../../../colors.scss';

footer {
	background-color: $footerBg;
	position: fixed;
	bottom: 0;
	z-index: 3;
	right: 0;
	left: 220px;
	.footer-content {
		display: flex;
		justify-content: center;
		padding-top: 20px;
		padding-bottom: 10px;
		width: 100%;
		max-width: 1200px;
		.group {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 18px;
			align-items: stretch;
			.item {
				padding-bottom: 10px;
				padding-top: 10px;
				padding-right: 18px;
				border-right: 1px solid #cecdcd;
				h3 {
					margin-top: 0;
					margin-bottom: 6px;
					font-family: 'Red Hat Display', sans-serif;
					font-weight: 700;
				}
				a {
					color: $blue200;
					display: block;
					line-height: 21px;
				}
				.info-item-wrap {
					span {
						color: $gray400;
					}
					a {
						display: inline;
						color: $blue200;
					}
				}
			}
		}
	}
}

.mobile {
	footer {
		left: 0;
	}
}

@media  screen and (max-width: 600px) {
	footer {
		position: relative;
		left: 0;
	}
}