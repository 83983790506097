@import '../../../colors.scss';

.no-requests-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;
  h4 {
    text-align: center;
  }
}
.requests-multipanel-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 50px;
  .filter-by-status-wrapper {
    grid-column: 1 / 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    .status-tabs-holder {
      display: flex;
      .status-tab-holder {
        align-self: center;
        padding: 12px 18px;
        background-color: $white;
        color: $gray200;
        cursor: pointer;
        &.active {
          background-color: $blue777;
          color: $white;
        }
      }
    }
  }
  .no-requests-filter-text {
    text-align: center;
    grid-column: 1/3;
    margin-top: 30px;
  }
  .new-request-btn-holder {
    grid-column: 2 / 2;
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
  }
  .multipanel-main {
    .request-summary-wrapper {
      border: 1px solid $inputBorder;
      position: relative;
      border-bottom: none;
      background-color: #f9f9f9;
      cursor: pointer;
      .summary-wrapper {
        padding: 20px;
        .summary-top-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          .status-chip {
            background-color: $blue777;
            border-radius: 99px;
            padding: 5px 9px;
            color: $white;
            font-family: 'Red Hat Display', sans-serif;
            font-weight: 700;
            font-size: 11px;
            &.in-progress {
              background-color: $yellowFAB;
            }
            &.resolved {
              background-color: $green26F;
            }
            &.completed {
              background-color: $green26F;
            }
          }
          .sr-number {
            color: $gray900;
            text-transform: uppercase;
          }
          .urgent {
            color: $errorText;
            font-family: 'Red Hat Display', sans-serif;
            font-weight: 700;
          }
        }
        .summary-bottom-wrapper {
          margin-top: 8px;
          .submitted-date {
            color: $gray900;
          }
        }
        .icon-holder {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            width: 20px;
            color: $gray400;
          }
        }
      }
      &.active {
        background-color: $white;
        cursor: auto;
        .summary-wrapper {
          .icon-holder {
            display: none;
          }
        }
      }
      &:last-of-type {
        border-bottom: 1px solid $inputBorder;
      }
    }
  }
  .multipanel-sidebar, .content-wrapper {
    .request-content-wrapper {
      .request-progress {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 20px;
        border-bottom: 1px solid $inputBorder;
        .progress-item-wrapper {
          .progress-item-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .step {
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: $whiteCC;
              border-radius: 50%;
              font-size: 21px;
              color: $white;
            }
            .label {
              font-size: 14px;
              color: $whiteCC;
              margin-top: 8px;
              display: block;
            }
            .date {
              color: $whiteD9;
              margin-top: 3px;
            }
            &::after {
              content: '';
              display: block;
              height: 1px;
              width: 50%;
              position: absolute;
              background-color: $inputBorder;
              top: 19px;
              position: absolute;
              right: -25%;
            }
          }
          &:last-of-type {
            .progress-item-holder {
              &::after {
                display: none;
              }
            }
          }
          &:not(.disabled) {
            .progress-item-holder {
              .step {
                background-color: $blue777;
              }
              .label {
                color: $gray400;
              }
            }
          }
          &.submitted {
            .progress-item-holder {
              .step {
                background-color: $blue000;
              }
              .label {
                color: $gray400;
              }
            }
          }
          &.in-progress {
            .progress-item-holder {
              .step {
                background-color: $blue000;
              }
              .label {
                color: $gray400;
              }
            }
          }
          &.resolved {
            .progress-item-holder {
              .step {
                background-color: $blue000;
              }
              .label {
                color: $gray400;
              }
            }
          }
          &.completed {
            .progress-item-holder {
              .step {
                background-color: $blue000;
              }
              .label {
                color: $gray400;
              }
            }
          }
        }
      }
      .request-info {
        border-bottom: 1px solid $inputBorder;
        padding: 20px;
        .info-text-item {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .feedback {
        border-bottom: 1px solid $inputBorder;
        padding: 20px;
        .info-text-item {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .attachments {
        position: relative;
        border-bottom: 1px solid $inputBorder;
        padding: 20px;
        min-height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        .no-attachments-box {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }
      .amend-form {
        padding: 20px;
      }
      .amendments {
        border-bottom: 1px solid $inputBorder;
        padding: 20px;
        .info-text-item {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .amend-btn-holder {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
}

.mobile {
  .requests-multipanel-wrapper {
    grid-template-columns: 1fr;
    max-width: 767px;
    margin: auto;
    .multipanel-main {
      grid-column: 1 / 3;
    }
    .request-content-wrapper {
      border-top: 1px solid $inputBorder;
      margin-top: 20px;
      .attachments {
        .loading-overlay {
          top: 0;
        }
      }
    }
    .content-wrapper {
      padding: 20px;
    }
  }
}
@media screen and (max-width: 676px) {
  .requests-multipanel-wrapper {
    .filter-by-status-wrapper {
      order: 2;
      margin-top: 0;
      .status-tabs-holder {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
    .new-request-btn-holder {
      order: 1;
      grid-column: 1/3;
      justify-content: center;
    }
    .no-requests-filter-text {
      order: 3;
    }
    .multipanel-main {
      order: 3;
    }
  }
}