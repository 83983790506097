@import '../../colors.scss';

.imprint-holder {
  .section {
    margin-top: 50px;
    h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    h3 {
      font-size: 14px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      margin-bottom: 18px;
      line-height: 23px;
    }
    span, a {
      font-size: 14px;
    }
  }
}