@import '../../colors.scss';

.no-contracts-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 60px;
  h3 {
    font-size: 18px;
    line-height: 24px;
    color: $gray400;
    text-align: center;
    max-width: 450px;
  }
  .contract-image {
    svg {
      width: 90px;
      height: 130px;
    }
  }
}