@import '../../../colors.scss';

.custom-uplaod-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  .upload-file-button {
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 131px;
    height: 100px;
    border: 1px solid $inputBorder;
    color: $placeholderColor;
    font-size: 50px;
    cursor: pointer;
  }
  input {
    display: none;
  }
}