@import './colors.scss';

@font-face {
  font-family: 'Red Hat Display';
  src: URL('./assets/fonts/Red_Hat_Display/static/RedHatDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Red Hat Display';
  src: URL('./assets/fonts/Red_Hat_Display/static/RedHatDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
}

* {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  font-size: 14px;
  box-sizing: border-box!important;
  line-height: 1;
  margin: 0;
  padding: 0;
}

.mt-60 {
  margin-top: 60px;
}

.cfGMLl {
  right: 15px!important;
  left: unset!important;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.wrapper {
  padding: 60px 30px;
  max-width: 1000px;
  margin: 0 auto;
}

h1, h2, h3, h4, p, label {
  color: $mainTextColor;
}

h4 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
}

.page-heading {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px !important;
  h1 {
    margin: 0;
    font-size: 45px;
    text-align: center;
    line-height: 54px;
    color: $mainTextColor;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.page-subheading {
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 8px;
  margin-top: -20px;
  padding: 13px 17px;
  padding-top: 0px;
  p {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  }
}

.mobile {
  .page-heading {
    h1 {
      font-size: 35px;
      line-height: 44px;
    }
  }
}

.main-btn {
  text-transform: none!important;
  font-family: 'Red Hat Display', sans-serif!important;
  font-weight: 400;
  background-color: $blue200!important;
  border-radius: 0!important;
  box-shadow: none!important;
  font-size: 14px!important;
}

.secondary-btn {
  text-transform: none!important;
  font-family: 'Red Hat Display', sans-serif!important;
  font-weight: 400;
  background-color: transparent!important;
  color: $mainTextColor!important;
  border-radius: 0!important;
  box-shadow: none!important;
  font-size: 14px!important;
  padding: 0!important;
  border-bottom: 2px solid $blue000!important;
}

.form-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 33px;
  max-width: 460px;
  margin: auto;
}

.buttons-holder {
  display: flex;
  gap: 20px;
  margin-top: 27px;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.column {
    flex-direction: column;
  }
  &.gap-10 {
    gap: 10px;
  }
  &.margin-t-50 {
    margin-top: 50px;
  }
}

.info-text-item {
  text-align: center;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 9px;
  label {
    color: $gray900;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  &.align-left {
    text-align: start;
  }
}

.btn-processing-holder,
.custom-select-loading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  .loader {
    width: 20px;
    height: 20px;
    border: 2px solid $white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 2px 0px;
  }
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

.custom-select-loading-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  .loader {
    width: 40px;
    height: 40px;
    border: 4px solid $blue200;
    border-bottom-color: transparent;
    margin: 3px 0px;
  }
}

.modal, .loading-overlay {
  z-index: 2;
  background-color: $white;
  position: fixed;
  left: 220px;
  right: 0;
  top: 0;
  bottom: 0;
  .loader {
    width: 20px;
    height: 20px;
    border: 2px solid $white;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 2px 0px;
    &.uploading {
      animation: none;
      border: none;
      .uploading-animation-holder {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -8px;
        .uploading-icon-holder {
          svg {
            width: 40px;
            color: $blue200;
          }
        }
        .uploading-dots-holder {
          display: flex;
          gap: 4px;
          .uploading-dot {
            height: 8px;
            width: 8px;
            border-radius:50%;
            transform: scale(0);
            background-color: $blue200;
            animation: scaling 2.5s ease-in-out infinite;
            display: inline-block;
          }
          .uploading-dot:nth-child(0){
            animation-delay:0s;
          }
          .uploading-dot:nth-child(1){
            animation-delay:0.2s;
          }
          .uploading-dot:nth-child(2){
            animation-delay:0.4s;
          }
          .uploading-dot:nth-child(3){
            animation-delay:0.6s;
          }
          .uploading-dot:nth-child(4){
            animation-delay:0.8s;
          }
          .uploading-dot:nth-child(5){
            animation-delay:1s;
          }
        }
      }
    }
  }
  @keyframes scaling{
    0%, 100%{
      transform: scale(0.2);
      background-color: $blue200;
    }
    40%{
      transform: scale(1);
      background-color: $blue200;
    }
    50%{
      transform: scale(1);
      background-color: $blue200;
    }
  }
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

.modal {
  .wrapper {
    margin-top: 50px;
    h4 {
      text-align: center;
      max-width: 460px;
      margin: auto;
    }
  }
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  &.redirect-overlay {
    background-color: $white!important;
    right: 0;
    left: 0;
  }
  &.absolute {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    width: 100%;
    right: 0;
    left: 0;
  }
  .loader {
    width: 50px;
    height: 50px;
    border: 5px solid $blue300;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: 2px 0px;
  }
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  }
}

.mobile {
  .modal, .loading-overlay {
    z-index: 2;
    left: 0;
    right: 0;
    top: 65px;
    bottom: 0;
  }
}

.subpage-head-content {
  text-align: center;
  h3 {
    font-size: 26px;
    line-height: 32px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    text-align: center;
    color: $mainTextColor;
    margin-bottom: 10px;
  }

  p{
    font-size: 16px;
    line-height: 24px;
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }
}